import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useRfqItems() {
  const rfqItems = ref([])
  const rfqItem = ref({})
  const loading = ref(false)
  const searchRfq = ref(null)

  const getRfqItems = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get('/rfq-items', { params })
        .then(response => {
          const { data } = response.data
          rfqItems.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getRfqItem = id => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get(`/rfq-items/${id}`)
        .then(response => {
          const { data } = response
          rfqItem.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    // variables
    loading,
    searchRfq,
    rfqItems,
    rfqItem,

    // functions
    getRfqItems,
    getRfqItem,
  }
}
