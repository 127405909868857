<template>
  <v-card>
    <v-card-title>RFQ Supplier</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="$store.getters['auth/can'](['create pre-repairs'])"
        color="primary"
        class="mr-2"
        :disabled="saving"
        @click="saveRfqSupplier"
      >
        Save
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="form.quotation_number"
              label="Quotation No"
              outlined
              dense
              disabled
              hide-details
              placeholder="Auto generated"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <supplier-autocomplete
              v-if="supplierLoaded || $router.currentRoute.name === 'rfq-suppliers-create'"
              v-model="form.supplier_id"
              :initial="initialSupplier"
              :error-message="errors.supplier_id"
              @setSupplier="setSupplier"
            ></supplier-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-tabs
      v-model="rfqTab"
      fixed-tabs
    >
      <v-tab>
        <h4>Items ({{ form.items.length }})</h4>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="rfqTab">
      <v-tab-item>
        <v-card>
          <v-card-text>
            <template>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Item No
                      </th>
                      <th class="text-left">
                        Description
                      </th>
                      <th class="text-left">
                        Unit Cost
                      </th>
                      <th class="text-left">
                        Unit Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key) in form.items"
                      :key="item.name"
                    >
                      <td style="width: 10%">
                        {{ key + 1 }}
                      </td>
                      <td style="width: 60%">
                        {{ item.item.description }}
                      </td>
                      <td style="width: 10%">
                        {{ item.unit_cost }}
                      </td>
                      <td style="width: 20%">
                        <v-text-field
                          v-model="item.unit_price"
                          label=""
                          placeholder="Enter Supplier Price"
                          outlined
                          dense
                          hide-details
                          type="number"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import {
  mdiPlus, mdiCalendarCheckOutline, mdiSpeedometer, mdiClose, mdiCogOutline,
} from '@mdi/js'
import axios from '@axios'
import { useRouter } from '@core/utils'
import router from '@/router'
import useRfqSuppliers from './useRfqSuppliers'
import SupplierAutocomplete from '../suppliers/SupplierAutocomplete.vue'
import useRfqItems from './useRfqItems'
import useRfq from './useRfq'

const formInit = () => ({
  rfq_id: null,
  quotation_number: null,
  supplier_id: null,
  items: [],
  id: null,
})
export default {
  components: {
    SupplierAutocomplete,
  },
  setup() {
    const saving = ref(false)
    const rfqTab = ref(null)
    const form = ref(formInit())
    const errors = ref([])
    const initialSupplier = ref([])
    const supplierLoaded = ref(false)

    const { route } = useRouter()
    const { rfqSupplier, getRfqSupplier } = useRfqSuppliers()
    const { rfqItems, getRfqItems } = useRfqItems()
    const { rfq, getRfq } = useRfq()
    const resetForm = () => {
      form.value = formInit()
    }

    const saveRfqSupplier = () => {
      saving.value = true
      if (route.value.name === 'rfq-suppliers-edit') {
        axios
          .put(`/rfq-suppliers/${router.currentRoute.params.rfq_supplier_id}`, form.value)
          .then(() => {
            saving.value = false
            router.push({ name: 'rfq' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      } else {
        axios
          .post('/rfq-suppliers', form.value)
          .then(() => {
            saving.value = false
            router.push({ name: 'rfq' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      }
    }

    const setSupplier = item => {
      form.value.supplier_id = item?.id
    }

    onMounted(() => {
      supplierLoaded.value = false

      saving.value = true

      if (route.value.name === 'rfq-suppliers-edit') {
        // get rfq_supplier
        getRfqSupplier(router.currentRoute.params.rfq_supplier_id).then(response => {
          const { data } = response
          form.value.id = data.id
          form.value.rfq_id = data.rfq_id
          form.value.quotation_number = data.rfq.quotation_number
          form.value.supplier_id = data.supplier.id
          initialSupplier.value = [data.supplier]

          supplierLoaded.value = true

          // get rfq_items by supplier
          getRfqItems({ rfq_id: data.rfq_id, rfq_supplier_id: router.currentRoute.params.rfq_supplier_id }).then(r => {
            form.value.items = r.data

            // modify form.items
            form.value.items = form.value.items.map(item => {
              const container = item

              // get first item since api response was already filtered by rfq_supplier_id
              const itemBid = item.supplier_items[0]
              if (itemBid) {
                container.unit_price = itemBid.unit_price
              }

              return container
            })

            saving.value = false
          })
        })
      } else if (router.currentRoute.params.id) {
        // get rfq
        getRfq(router.currentRoute.params.id).then(r => {
          form.value.rfq_id = r.data.id
          form.value.quotation_number = r.data.quotation_number
        })

        // get rfq_items
        getRfqItems({ rfq_id: router.currentRoute.params.id }).then(r => {
          form.value.items = r.data

          saving.value = false
        })
      }
    })

    return {
      saving,
      rfqTab,
      form,
      errors,

      supplierLoaded,
      initialSupplier,

      rfqSupplier,
      getRfqSupplier,

      rfqItems,
      getRfqItems,

      rfq,
      getRfq,

      resetForm,
      saveRfqSupplier,

      setSupplier,

      // icons
      icons: {
        mdiPlus,
        mdiCalendarCheckOutline,
        mdiSpeedometer,
        mdiCogOutline,
        mdiClose,
      },
    }
  },
}
</script>
