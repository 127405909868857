import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useRfqSuppliers() {
  const rfqSuppliers = ref([])
  const rfqSupplier = ref({})
  const loading = ref(false)
  const searchRfqSupplier = ref(null)

  const getRfqSuppliers = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .post('/rfq-suppliers/filter', params)
        .then(response => {
          const { data } = response.data
          rfqSuppliers.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getRfqSupplier = id => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get(`/rfq-suppliers/${id}`)
        .then(response => {
          const { data } = response
          rfqSupplier.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    // variables
    loading,
    searchRfqSupplier,
    rfqSuppliers,
    rfqSupplier,

    // functions
    getRfqSuppliers,
    getRfqSupplier,
  }
}
